import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Index from './pages/Index';
import Status from './pages/Status';
import Monitoramento from './pages/Monitoramento';

export default function Routes(){
    return (
        <BrowserRouter>
            <Route path='/' exact component={Index} />
            <Route path='/monitoramento' exact component={Monitoramento} />
            <Route path='/status/:tipo/:hash?' exact component={Status} />
        </BrowserRouter>
    );
}