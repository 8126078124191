import React, { Component } from 'react';
import $ from 'jquery';
import './css/Style.css';
import './css/normalize.css';
import './css/demo.css';
import './css/component.css';

class Index extends Component {
    componentDidMount() {
        var api_baseUrl = 'http://apibdmep.inmet.gov.br'; 
        var novo_cadastro = true; // Verifica se é um usuário novo ou alteração de cadastro

        async function montarEstacoes(regiao, tipo_estacao, tipo_estacao_sigla){
            await $.get(`${api_baseUrl}/${tipo_estacao_sigla}/R/${regiao}`, function(response) {
                if(response){
                    for(var i = 0; i < response.length; i++){
                        $(`#${tipo_estacao}-${regiao}`).append(`
                            <div class="grid grid-template-columns-1 no-border">
                                <div class="item">
                                    <label class="checkbox_container">${response[i].DC_NOME} (${response[i].CD_ESTACAO}) - [${response[i].SG_ESTADO}]
                                        <input type="checkbox" name="estacoes" id="${response[i].CD_ESTACAO}" value="${response[i].CD_ESTACAO}" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        `);
                    }
                }
            });
        }
        
        function validateEmail(email){
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }
        
        $(document).ready(async function() {
            montarEstacoes('N',  'Convencionais', 'M');
            montarEstacoes('NO', 'Convencionais', 'M');
            montarEstacoes('SU', 'Convencionais', 'M');
            montarEstacoes('S',  'Convencionais', 'M');
            montarEstacoes('CO', 'Convencionais', 'M');
            montarEstacoes('N',  'Automaticas',   'T');
            montarEstacoes('NO', 'Automaticas',   'T');
            montarEstacoes('SU', 'Automaticas',   'T');
            montarEstacoes('S',  'Automaticas',   'T');
            montarEstacoes('CO', 'Automaticas',   'T');
            
            $('.instrucoes').css('display', 'block');
            
            $('.instrucoes_proximo').click(function(){
                $('.instrucoes').css('display', 'none');
                $('.formulario_1').css('display', 'block');
            });
            
            $('.form1_proximo').click(async function(){
                var email = $('.formulario_1 .email').val();
                $('.formulario_1 .error').find('center').remove();

                if(validateEmail(email)){
                    $('.formulario_1').css('display', 'none');
                    $('.formulario_2').css('display', 'block');
                    $('.formulario_2 .email').val(email);

                await $.ajax({
                    url : `${api_baseUrl}/monitoramento/recovery`,
                    type : 'POST',
                    data : { email }
                }).done(function(response){
                    if(response){
                        novo_cadastro = false;
                        $.each($("input[name='tipo_dados']"), function(){
                            if($(this).val() === response.tipo_dados)
                                $(this).prop('checked', true);
                        });
                        
                        // eslint-disable-next-line
                        var estacoes_recuperadas = String(response.estacoes.slice(1, -1)).replace(/\"/g, '').split(',');
                        for(var i = 0; i < estacoes_recuperadas.length; i++)
                            $(`#${estacoes_recuperadas[i]}`).prop('checked', true);
                    }
                });
                }else{
                    $('.formulario_1 .error').append(`<center><font size="4" color="red">E-mail inválido.</center></font>`);
                }
            });

            $('.form2_proximo').click(function(){
                var email = $('.formulario_2 .email').val();
                var tipo_dados = $("input[name='tipo_dados']:checked").val();
                var estacoes = [];
                $.each($("input[name='estacoes']:checked"), function(){
                    estacoes.push($(this).val());
                });

                const dataPost = {
                    email,
                    tipo_dados,
                    estacoes,
                };
                
                $.ajax({
                    url : novo_cadastro ? `${api_baseUrl}/monitoramento` : `${api_baseUrl}/monitoramento/update`,
                    type : 'POST',
                    data : dataPost
                });

                $('.formulario_2').css('display', 'none');
                $('.msgFinal').css('display', 'block');
            });

            $('.msgFinal_inicio').click(function(){
                $('.instrucoes').css('display', 'block');
                $('.msgFinal').css('display', 'none');
                window.location.reload(true);
            });
        });

        $('#label_convencionais').click(function(){
            if($('#section_convencionais').is(':visible'))
                $('#section_convencionais').css('display', 'none');
            else
                $('#section_convencionais').css('display', 'grid');
        });


        $('#label_automaticas').click(function(){
            if($('#section_automaticas').is(':visible'))
                $('#section_automaticas').css('display', 'none');
            else
                $('#section_automaticas').css('display', 'grid');
        });
    }
    
    render() {
        return (
            <div className="App">
            <div className="container">
            <ul id="gn-menu" className="gn-menu-main">
            <li className="inmetName"><a href="http://portal.inmet.gov.br/">Instituto Nacional de Meteorologia</a></li>
            </ul>
            <div id="containerFullScreen">
            
            <div className="instrucoes">
            <h2>
            Caro Usuário,
            <br /><br />
            O Banco de Dados Meteorológico de Pesquisa fornece dados em formato CSV.
            <br />
            Na próxima tela, favor preencher todos os campos com atenção.
            <br />
            O e-mail será o canal de confirmação e recepção dos dados desejados. Nosso sistema envia e-mails com remetente bdmep@inmet.gov.br.
            <br />
            A confirmação da solicitação é impreterível para o inicio do processo.
            <br />
            Observar a caixa de seleção ABRANGÊNCIA: a partir dela serão exibidas as estações meteorológicas contidas na área selecionada. Para selecionar mais de uma estação, segurar a tecla CRTL enquanto marcar as estações. Para selecionar todas de uma vez, apertar: "CRTL+a". Para visualisar a distribuição espacial das estações, acessar o 
            {/* eslint-disable-next-line */}
            <a href="http://mapas.inmet.gov.br" target="_blank"> Mapa de Estações</a>.
            <br />
            Caso seja selecionada uma data inicial prévia a data de criação de alguma estação, para esta estação, a data será trocada automaticamente.
            <br />
            Caso seja selecionada uma data final prévia a data de criação de alguma estação, para esta estação, você receberá um email avisando da não existencia de dados no período.
            <br />
            Para mais de uma requisição, favor aguardar o término do primeiro processamento.
            <br />
            </h2>
            <br /><br /><br />
            {/* eslint-disable-next-line */}
            <a href="#" value="Prosseguir" className="instrucoes_proximo submit"><center>Prosseguir</center></a>
            </div>
            
            <div className="formulario_1">
            <section><span className="label">Email:</span><br /><input type="email" className="email" /></section>
            <br />
            <span className="error"></span>
            <br /><br />
            {/* eslint-disable-next-line */}
            <a href="#" value="Gerar Modelo" className="submit form1_proximo"><center>Prosseguir</center></a>
            </div>
            
            
            <div className="formulario_2">
            <section><span className="label">Email:</span><br /><input type="email" className="email" disabled="disabled" /></section>
            <section>
                <span className="label">Tipo de Dados:</span>
                <br /><br />
                <label className="radio_container">Dados Horários
                    <input type="radio" name="tipo_dados" value="H"/>
                    <span className="checkmark"></span>
                </label>
                <label className="radio_container">Dados Diários
                    <input type="radio" name="tipo_dados" value="D"/>
                    <span className="checkmark"></span>
                </label>

                <br /><br />
                <span className="label" id="label_convencionais">Estações Convencionais ▼</span>
                <br /><br />
                <section className="grid grid-template-columns-5" id="section_convencionais">
                    <div className="item" id="Convencionais-N"><h3>Norte</h3></div>
                    <div className="item" id="Convencionais-NO"><h3>Nordeste</h3></div>
                    <div className="item" id="Convencionais-S"><h3>Sul</h3></div>
                    <div className="item" id="Convencionais-SU"><h3>Sudeste</h3></div>
                    <div className="item" id="Convencionais-CO"><h3>Centro-Oeste</h3></div>
                </section>
                
                <br /><br />
                <span className="label" id="label_automaticas">Estações Automáticas ▼</span>
                <br /><br />
                <section className="grid grid-template-columns-5" id="section_automaticas">
                    <div className="item" id="Automaticas-N"><h3>Norte</h3></div>
                    <div className="item" id="Automaticas-NO"><h3>Nordeste</h3></div>
                    <div className="item" id="Automaticas-S"><h3>Sul</h3></div>
                    <div className="item" id="Automaticas-SU"><h3>Sudeste</h3></div>
                    <div className="item" id="Automaticas-CO"><h3>Centro-Oeste</h3></div>
                </section>
            </section>
            <br />
            <span className="error"></span>
            <br /><br />
            {/* eslint-disable-next-line */}
            <a href="#" value="Gerar Modelo" className="submit form2_proximo"><center>Prosseguir</center></a>
            </div>
            
            <div className="msgFinal">
            <h2>Confirmação</h2>
            <hr />
            <span className="faq">
            <ul>
            <li>Confirme a sua requisição clicando no link recebido por email.</li>
            </ul>
            <br /><br /><br />
            {/* eslint-disable-next-line */}
            <a href="#" value="Inicio" className="submit msgFinal_inicio"><center>Voltar para o inicio</center></a>
            </span>
            </div>
            
            </div>
            </div>
            </div>
            );
        }
    }
    export default Index;
    
