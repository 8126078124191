import React, { Component } from 'react';
import $ from 'jquery';
import LoadingTransparent from '../assets/loadingtransparent.svg';
import './css/Style.css';

class Index extends Component {
  
  componentDidMount() {
    const { tipo, hash }      = this.props.match.params,
          api_baseUrl   = 'https://apibdmep.inmet.gov.br';

    $(document).ready(function (){
        $.get(`${api_baseUrl}/${tipo}/status/${hash}`, function(response) {
          if(response.status === '2'){
            $('.loading').css('display', 'block');
            $('.msg').append(`<center><font size="8" color="#0F4098">Sua requisição está sendo processada, acesse novamente mais tarde!</center></font>`);
          }else if(response.status === '3'){
            $('.msg').append(`<center><font size="8" color="#0F4098">Sua requisição está completa e foi enviada por email!</center></font>`);
          }else{
            $('.msg').append(`<center><font size="8" color="#0F4098">Sua requisição foi confirmada e está na fila para ser processada!</center></font>`);
          }
        });
    });
  }
  
  render() {
    return (
      <div className="App" style={
        {
          'position':'fixed',
          'top':'50%',
          'bottom':'0',
          'left':'0',
          'right':'0',
        }
      }>
        <div>
          <span className="msg"></span>
          <center><img src={LoadingTransparent} alt="Loading" className="loading" style={{ 'display' : 'none' }}/></center>
        </div>
      </div>
      );
    }
  }
  export default Index;
  
