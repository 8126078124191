import React, { Component } from 'react';
import datepickerFactory from 'jquery-datepicker';
import datepickerptBRFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-pt-BR';
import $ from 'jquery';
import './css/datepicker.css';
import './css/Style.css';
import './css/normalize.css';
import './css/demo.css';
import './css/component.css';

class Index extends Component {
  componentDidMount() {
    $(function() {
      datepickerFactory($);
      datepickerptBRFactory($);
      
      $('.dataInicio, .dataFim').datepicker({ maxDate : new Date() });
    });
    
    let api_baseUrl = 'https://apibdmep.inmet.gov.br'; 
    let apiTempo_baseUrl = 'https://apitempo.inmet.gov.br'; 
    
    async function montarEstacoes(regiao, tipo_estacao_sigla){
      await $.get(`${api_baseUrl}/${tipo_estacao_sigla}/R/${regiao}`, async function(response) {
        if(response){
          for(let i = 0; i < response.length; i++) {
            await $(`#estacoes-${regiao} .dados-estacao`).append(`
              <div class="grid grid-template-columns-1 no-border">
                <div class="item">
                  <label class="checkbox_container">${response[i].DC_NOME} (${response[i].CD_ESTACAO}) - [${response[i].SG_ESTADO}]
                    <input type="checkbox" name="estacoes" id="${response[i].DC_NOME} (${response[i].CD_ESTACAO}) - [${response[i].SG_ESTADO}]" value="${response[i].CD_ESTACAO}" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            `);
          }
        }
      });
    }

    async function montarVariaveis(tipo_estacao, tipo_dados) {
      let estacao = tipo_estacao === 'Automaticas' ? 'A301' : '83377'
      await $.get(`${apiTempo_baseUrl}/BNDMET/atributos/${estacao}/${tipo_dados}`, function(response) {
        if(response) {
          for(let i = 0; i < response.length; i++) {
            $('#div-variaveis').append(`
              <div class="grid grid-template-columns-1 no-border">
                <div class="item">
                  <label class="checkbox_container">${response[i].DESCRICAO}
                    <input type="checkbox" name="variaveis" id="${response[i].DESCRICAO}" value="${response[i].CODIGO}" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            `)
          }
        }
      })
    }
    
    function validateEmail(email){
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    async function mountForm(tipo_dados, tipo_estacao, abrangencia) {
      if(tipo_dados && tipo_estacao && abrangencia) {
        let tipo = tipo_estacao === 'T' ? 'Automaticas' : 'Convencionais'

        $('#div-variaveis').empty()
        $('#estacoes-N .dados-estacao').empty()
        $('#estacoes-NO .dados-estacao').empty()
        $('#estacoes-SU .dados-estacao').empty()
        $('#estacoes-S .dados-estacao').empty()
        $('#estacoes-CO .dados-estacao').empty()
        
        await montarVariaveis(tipo, tipo_dados);

        if(abrangencia === 'P') {
          await montarEstacoes('N',  tipo_estacao)
          await montarEstacoes('NO', tipo_estacao)
          await montarEstacoes('SU', tipo_estacao)
          await montarEstacoes('S',  tipo_estacao)
          await montarEstacoes('CO', tipo_estacao)
        } else if(abrangencia === 'R') {
          let regioes = [];
          $.each($("input[name='regioes']:checked"), function(){
            regioes.push($(this).val());
          });

          for(let i = 0; i < regioes.length; i++) {
            await montarEstacoes(regioes[i], tipo_estacao)
          }
        }
      }
    }
    
    $(document).ready(async function() {    
      $('.instrucoes').css('display', 'block');
      
      $('.instrucoes_proximo').click(function(){
        $('.instrucoes').css('display', 'none');
        $('.formulario_1').css('display', 'block');
      });
      
      $('.form1_proximo').click(async function(){
        $('.formulario_1 .error').find('center').remove();
        let email = $('.formulario_1 .email').val();
        $('.formulario_1 .error').find('center').remove();
        
        if(validateEmail(email)){
          let requisicao = []
          await $.ajax({
            url : `${api_baseUrl}/requisicao/count`,
            type : 'POST',
            data : { email }
          }).done(function(response){ requisicao = response });
          if(requisicao.length !== 0) {
            if (parseInt(requisicao[0].status) === 0) {
              $('.formulario_1 .error').append(`<center><font size="4" color="red">Você possui uma requisição não confirmada, verifique seu email.</center></font>`);
            } else {
              $('.formulario_1 .error').append(`<center><font size="4" color="red">Seu email já possui requisições na fila, aguarde elas serem atendidas para fazer outra.</center></font>`);
            }
          } else {
            $('.formulario_1').css('display', 'none');
            $('.formulario_2').css('display', 'block');
            $('.formulario_2 .email').val(email);
          }
        }else{
          $('.formulario_1 .error').append(`<center><font size="4" color="red">E-mail inválido.</center></font>`);
        }
      });
      
      $('.form2_proximo').click(async function(){
        $('.error').find('center').remove();
        
        let data_inicio = $('.dataInicio').val().split('/').reverse().join('-');
        let data_fim = $('.dataFim').val().split('/').reverse().join('-');
        
        let variaveis = []
        let variaveis_nomes = ''
        $.each($("input[name='variaveis']:checked"), function(){
          variaveis.push($(this).val());
          variaveis_nomes += `<li>${$(this).attr('id')}</li>`
        });
        
        let estacoes = [];
        let estacoes_nomes = ''
        $.each($("input[name='estacoes']:checked"), function(){
          estacoes.push($(this).val());
          estacoes_nomes += `<li>${$(this).attr('id')}</li>`
        });

        let tipo_pontuacao = $("input[name='tipo_pontuacao']:checked").val();
         
        data_inicio = new Date(data_inicio);
        data_fim = new Date(data_fim);
        if(!isNaN(data_inicio.getTime()) && !isNaN(data_fim.getTime())){
          data_inicio = new Date(Date.UTC(data_inicio.getUTCFullYear(), data_inicio.getUTCMonth(), data_inicio.getUTCDate(), data_inicio.getUTCHours() , data_inicio.getUTCMinutes(), data_inicio.getUTCSeconds())).toISOString().split('T')[0].split('.')[0].split(':')[0] 
          data_fim = new Date(Date.UTC(data_fim.getUTCFullYear(), data_fim.getUTCMonth(), data_fim.getUTCDate(), data_fim.getUTCHours() , data_fim.getUTCMinutes(), data_fim.getUTCSeconds())).toISOString().split('T')[0].split('.')[0].split(':')[0]   
          if(((new Date(data_fim).getTime() - new Date(data_inicio).getTime()) / (1000 * 3600 * 24)) >= 0) {
            if(variaveis.length === 0) {
              $('.error').append(`<center><font size="4" color="red">Variáveis não podem ficar em branco.</center></font>`);
            } else if (estacoes.length === 0) {
              $('.error').append(`<center><font size="4" color="red">Estações não podem ficar em branco.</center></font>`);
            } else if(tipo_pontuacao === undefined) {
              $('.error').append(`<center><font size="4" color="red">Um tipo de pontuação deve ser selecionado.</center></font>`);
            } else {
              $('.texto_confirmacao').find('div').remove()
              $('.texto_confirmacao').append(`
                <div style="padding: 10px; border: 1px solid black; margin: 10px">
                  <font size="5" color="black"><center>Tela de Confirmação</center></font>
                  <font size="3" color="red"><center><b>Só prossiga se todas as informações estiverem corretas!</b></center></font>
                  <b>Data Inicio:</b> ${data_inicio.split('-').reverse().join('/')}<br />
                  <b>Data Fim:</b> ${data_fim.split('-').reverse().join('/')}<br /><br />
  
                  <b>Variáveis:</b><br />
                  <ul>${variaveis_nomes}</ul>
  
                  <b>Estações:</b><br />
                  <ul>${estacoes_nomes}</ul>
                </div>
              `)
              $('.formulario_2').css('display', 'none');
              $('.confirmacao').css('display', 'block');
            }
          } else {
            $('.error').append(`<center><font size="4" color="red">Data Fim não pode ser menor que a Data Inicio.</center></font>`);
          }
        }else{
          $('.error').append(`<center><font size="4" color="red">Verifique as datas, pode ser que você digitou alguma data inválida.</center></font>`);
        }
      });

      $('.confirmacao_confirmar').click(function() {
        let data_inicio = $('.dataInicio').val().split('/').reverse().join('-');
        let data_fim = $('.dataFim').val().split('/').reverse().join('-');
        let email = $('.formulario_2 .email').val();
        let tipo_dados = $("input[name='tipo_dados']:checked").val();
        let tipo_estacao = $("input[name='tipo_estacao']:checked").val();
        let tipo_pontuacao = $("input[name='tipo_pontuacao']:checked").val();
        
        let variaveis = []
        $.each($("input[name='variaveis']:checked"), function(){
          variaveis.push($(this).val());
        });
        
        let estacoes = [];
        $.each($("input[name='estacoes']:checked"), function(){
          estacoes.push($(this).val());
        });

        const dataPost = {
          email,
          tipo_dados,
          tipo_estacao,
          variaveis,
          estacoes,
          data_inicio,
          data_fim,
          tipo_pontuacao
        };

        $.ajax({
          url : `${api_baseUrl}/requisicao`,
          type : 'POST',
          data : dataPost
        });

        $('.confirmacao').css('display', 'none');
        $('.msgFinal').css('display', 'block');
      });

      $('.confirmacao_voltar').click(function() {
        $('.formulario_2').css('display', 'block');
        $('.confirmacao').css('display', 'none');
      });

      $('.msgFinal_inicio').click(function(){
        $('.instrucoes').css('display', 'block');
        $('.msgFinal').css('display', 'none');
        window.location.reload(true);
      });
    });

    $('input[name=abrangencia]').click(function () {
      let tipo_dados = $('input[name=tipo_dados]:checked').val()
      let tipo_estacao = $('input[name=tipo_estacao]:checked').val()
      let abrangencia = $('input[name=abrangencia]:checked').val()

      mountForm(tipo_dados, tipo_estacao, abrangencia)

      if(abrangencia === 'R') {
        $('.box-regioes').css('display', 'block');
      } else {
        $('.box-regioes').css('display', 'none');
      }
    });

    $('input[name=tipo_dados]').click(function () {
      $('input[name=variaveis]').prop('checked', false)
      $('input[name=estacoes]').prop('checked', false)

      let tipo_dados = $('input[name=tipo_dados]:checked').val()
      let tipo_estacao = $('input[name=tipo_estacao]:checked').val()
      let abrangencia = $('input[name=abrangencia]:checked').val()

      mountForm(tipo_dados, tipo_estacao, abrangencia)
    })
    
    $('input[name=tipo_estacao]').click(function () {
      let tipo_dados = $('input[name=tipo_dados]:checked').val()
      let tipo_estacao = $('input[name=tipo_estacao]:checked').val()
      let abrangencia = $('input[name=abrangencia]:checked').val()

      mountForm(tipo_dados, tipo_estacao, abrangencia)
    })

    $('input[name=regioes]').click(function () {
      let tipo_dados = $('input[name=tipo_dados]:checked').val()
      let tipo_estacao = $('input[name=tipo_estacao]:checked').val()
      let abrangencia = $('input[name=abrangencia]:checked').val()

      mountForm(tipo_dados, tipo_estacao, abrangencia)
    })

    $('#label-regioes').click(function () {
      if($('#section-regioes').css('display') === 'none') {
        $('#section-regioes').css('display', 'block');
      } else {
        $('#section-regioes').css('display', 'none');
      }
    });

    $('#label-variaveis').click(function () {
      if($('#section-variaveis').css('display') === 'none') {
        $('#section-variaveis').css('display', 'block');
      } else {
        $('#section-variaveis').css('display', 'none');
      }
    });

    $('#label-estacoes').click(function () {
      if($('#section-estacoes').css('display') === 'none') {
        $('#section-estacoes').css('display', 'block');
      } else {
        $('#section-estacoes').css('display', 'none');
      }
    });

    $('input[name=variaveis-all]').click(function () {
      $('input[name=variaveis]').prop('checked', $('#variaveis-all').is(':checked'))
    })

    $('input[name=estacoes-all]').click(function () {
      $('input[name=estacoes]').prop('checked', $('#estacoes-all').is(':checked'))
    })
  }
  
  render() {
    return (
      <div className="App">
      <div className="container">
      <ul id="gn-menu" className="gn-menu-main">
      <li className="inmetName"><a href="https://portal.inmet.gov.br/">Instituto Nacional de Meteorologia</a></li>
      </ul>
      <div id="containerFullScreen">
      
      <div className="instrucoes">
      <h2>
      Caro(a) usuário(a), seja bem-vindo(a) ao Banco de Dados Meteorológicos do INMET.<br /><br />
      <mark>ATENÇÃO! Para Download imediato de pacote de dados anuais de TODAS as estações automáticas separadas por ano, <a href="https://portal.inmet.gov.br/dadoshistoricos" rel="noopener noreferrer" target="_blank"> clique aqui. </a></mark><br /><br />
      <mark>ATENÇÃO! Para coleta de dados horários de curta duração (até 6 meses) de uma estação, é aconselhado o uso da Tabela de Dados de Estação através do sistema <a href="https://tempo.inmet.gov.br/TabelaEstacoes/A001" rel="noopener noreferrer" target="_blank"> TEMPO. </a></mark><br /><br />
      Para escolher uma estação em particular, visualize  a distribuição espacial das estações no <a href="https://mapas.inmet.gov.br" target="_blank" rel="noopener noreferrer"> Mapa de Estações</a>.<br /><br />
      Siga as instruções para receber os dados meteorológicos da estação escolhida em formato CSV:<br /><br />
      (1) Clique em "Prosseguir" nesta tela, abaixo, e na próxima tela forneça seu endereço de e-mail.<br /><br />
      (2) Na tela seguinte, favor preencher todos os campos com atenção: tipo de estação, datas de início e fim, variáveis selecionadas e local. Envie sua solicitação.<br />
      Caso seja selecionada uma data inicial anterior à data de início de operação (fundação) de alguma estação, para esta estação, a data será substituída automaticamente para sua data de fundação.<br />
      Caso seja selecionada uma data final anterior à data de início de operação (fundação) de alguma estação, para esta estação, você receberá um e-mail informando sobre a indisponibilidade de dados no período.<br />
      Existe uma seleção de pontuação para que seja escolhido usar PONTO(.) ou VÍRGULA(,) como SEPARADOR DECIMAL<br /><br />
      (3) Um e-mail de confirmação será enviado automaticamente para o endereço fornecido, clique no link enviado para confirmá-lo.<br /><br /><br />

      Para efeito de controle, existe uma fila de processamento e quando sua requisição for iniciada você receberá um e-mail alertando. <br /><br />
      Ao término do processo, um e-mail de conclusão será enviado contendo o link de acesso aos dados selecionados; após 48h esses dados serão apagados.<br /><br />
      Para mais de uma requisição, favor aguardar o término do primeiro processamento. <br />
      </h2>
      <br />
      {/* eslint-disable-next-line */}
      <a href="#" value="Prosseguir" className="instrucoes_proximo submit"><center>Prosseguir</center></a>
      </div>
      
      <div className="formulario_1">
      <section><span className="label">Email:</span><br /><input type="email" className="email" /></section>
      <br />
      <span className="error"></span>
      <br /><br />
      {/* eslint-disable-next-line */}
      <a href="#" value="Gerar Modelo" className="submit form1_proximo"><center>Prosseguir</center></a>
      </div>

      <div className="formulario_2">
      <section><span className="label">Email:</span><br /><input type="email" className="email" disabled="disabled" /></section>
      <section>

      <span className="label">Tipo de Pontuação:</span>
      <br /><br />
      <label className="radio_container">Vírgula
      <input type="radio" name="tipo_pontuacao" value="V"/>
      <span className="checkmark"></span>
      </label>
      <label className="radio_container">Ponto
      <input type="radio" name="tipo_pontuacao" value="P"/>
      <span className="checkmark"></span>
      </label>

      <span className="label">Tipo de Dados:</span>
      <br /><br />
      <label className="radio_container">Dados Horários
      <input type="radio" name="tipo_dados" value="H"/>
      <span className="checkmark"></span>
      </label>
      <label className="radio_container">Dados Diários
      <input type="radio" name="tipo_dados" value="D"/>
      <span className="checkmark"></span>
      </label>
      <label className="radio_container">Dados Mensais
      <input type="radio" name="tipo_dados" value="M"/>
      <span className="checkmark"></span>
      </label>
      
      <span className="label">Tipo de Estação:</span>
      <br /><br />
      <label className="radio_container">Automáticas
      <input type="radio" name="tipo_estacao" value="T"/>
      <span className="checkmark"></span>
      </label>
      <label className="radio_container">Convencionais
      <input type="radio" name="tipo_estacao" value="M"/>
      <span className="checkmark"></span>
      </label>

      <span className="label">Abrangência:</span>
      <br /><br />
      <label className="radio_container">País
      <input type="radio" name="abrangencia" value="P"/>
      <span className="checkmark"></span>
      </label>
      <label className="radio_container">Região
      <input type="radio" name="abrangencia" value="R"/>
      <span className="checkmark"></span>
      </label>
     
      <section><span className="label">Data Início:</span><br /><input type="text" id="datepickerInicio" className="dataInicio" placeholder="dd/mm/yyyy" maxLength="10" size="10" /></section>
      <section><span className="label">Data Fim:</span><br /><input type="text" id="datepickerFim" className="dataFim" placeholder="dd/mm/yyyy" maxLength="10" size="10" /></section>
     
      <div className="box-regioes" style={{ display: 'none' }}>
        <br /><br />
        <span className="label" id="label-regioes">Regiões ▼</span>
        <br /><br />
        <section className="grid grid-template-columns-1" id="section-regioes">
          <div className="item" id="div-regioes">

            <div className="grid grid-template-columns-1 no-border">
              <div className="item">
                <label className="checkbox_container">Centro-Oeste
                  <input type="checkbox" name="regioes" id="CO" value="CO" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>

            <div className="grid grid-template-columns-1 no-border">
              <div className="item">
                <label className="checkbox_container">Nordeste
                  <input type="checkbox" name="regioes" id="NO" value="NO" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>

            <div className="grid grid-template-columns-1 no-border">
              <div className="item">
                <label className="checkbox_container">Norte
                  <input type="checkbox" name="regioes" id="N" value="N" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>

            <div className="grid grid-template-columns-1 no-border">
              <div className="item">
                <label className="checkbox_container">Sudeste
                  <input type="checkbox" name="regioes" id="SU" value="SU" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>

            <div className="grid grid-template-columns-1 no-border">
              <div className="item">
                <label className="checkbox_container">Sul
                  <input type="checkbox" name="regioes" id="S" value="S" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>

          </div>
        </section>
      </div>

      <div className="box-variaveis">
        <br /><br />
        <span className="label" id="label-variaveis">Váriaveis ▼</span>
        <br /><br />
        <div className="grid grid-template-columns-1 no-border">
          <div className="item">
            <label className="checkbox_container">Selecionar Todas as Variáveis
              <input type="checkbox" name="variaveis-all" id="variaveis-all" value="variaveis-all" />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
        <section className="grid grid-template-columns-1" id="section-variaveis">
          <div className="item" id="div-variaveis"></div>
        </section>
      </div>

      <div className="box-estacoes">
        <br /><br />
        <span className="label" id="label-estacoes">Estações ▼</span>
        <br /><br />
        <div className="grid grid-template-columns-1 no-border">
            <div className="item">
              <label className="checkbox_container">Selecionar Todas as Estações
                <input type="checkbox" name="estacoes-all" id="estacoes-all" value="estacoes-all" />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        <section className="grid grid-template-columns-5" id="section_estacoes">
          <div className="item" id="estacoes-N">
            <h3>Norte</h3>
            <div className="dados-estacao"></div>
          </div>
          <div className="item" id="estacoes-NO">
            <h3>Nordeste</h3>
            <div className="dados-estacao"></div>
          </div>
          <div className="item" id="estacoes-S">
            <h3>Sul</h3>
            <div className="dados-estacao"></div>
          </div>
          <div className="item" id="estacoes-SU">
            <h3>Sudeste</h3>
            <div className="dados-estacao"></div>
          </div>
          <div className="item" id="estacoes-CO">
            <h3>Centro-Oeste</h3>
            <div className="dados-estacao"></div>
          </div>
        </section>
      </div>
      
      </section>
      <br />
      <span className="error"></span>
      <br /><br />
      {/* eslint-disable-next-line */}
      <a href="#" value="Gerar Modelo" className="submit form2_proximo"><center>Prosseguir</center></a>
      </div>

      <div className="confirmacao">
        <div className="texto_confirmacao"></div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {/* eslint-disable-next-line */}
          <a href="#" value="Voltar" className="submit confirmacao_voltar"><center>Voltar</center></a>
          {/* eslint-disable-next-line */}
          <a href="#" value="Confirmar" className="submit confirmacao_confirmar"><center>Confirmar</center></a>
        </div>
      </div>

      
      <div className="msgFinal">
      <h2>Confirmação</h2>
      <hr />
      <span className="faq">
      <ul>
      <li>Confirme a sua requisição clicando no link recebido por email.</li>
      </ul>
      <br /><br /><br />
      {/* eslint-disable-next-line */}
      <a href="#" value="Inicio" className="submit msgFinal_inicio"><center>Voltar para o inicio</center></a>
      </span>
      </div>
      
      </div>
      </div>
      </div>
      );
    }
  }
  export default Index;
  
